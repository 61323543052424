import {type InferType, boolean, number, object, string} from 'yup'

import '@ansarada/sdk/schema/yup'

const defaultValidationSchema = object().shape({
  firstName: string().trim().required('Your first name is required'),
  lastName: string().trim().required('Your last name is required'),
  phoneNumber: string().trim().required('Your phone number is required'),
  password: string().when(['isSso', 'hasExistingPassword'], {
    is: (isSso: boolean, hasExistingPassword: boolean) =>
      !isSso && !hasExistingPassword,
    then: fieldSchema =>
      fieldSchema.required('Password is required').password(),
  }),
  jobTitleGroupName: string()
    .when('jobTitleId', {
      is: (jobTitleId: number | null) => !jobTitleId,
      then: string().required('Your organisation type is required'),
    })
    .required('Your organisation type is required'),
  jobTitleId: number()
    .nullable()
    .notOneOf([0, null, undefined], 'Your job title is required'),
  locationId: number()
    .nullable()
    .notOneOf([0, null, undefined])
    .required('Your city is required'),
  termsAndConditionsAccepted: boolean().oneOf([true]),
})

type ValidationSchemaKeys = keyof InferType<typeof defaultValidationSchema>

const buildValidationSchema = (requiredKeys: ValidationSchemaKeys[]) => {
  return defaultValidationSchema.pick(requiredKeys)
}

export {buildValidationSchema}
