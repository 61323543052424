import { Icon, SvgProps } from '../common/Icon';

export const Help = (props: SvgProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path d="M10 1C5.03 1 1 5.03 1 10C1 14.97 5.03 19 10 19C14.97 19 19 14.97 19 10C19 5.03 14.97 1 10 1ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" />
      <path d="M10.22 12.98H9.07996V14.2H10.22V12.98Z" />
      <path d="M11.64 6.08C11.3 5.9 10.91 5.8 10.48 5.8H7.94995V6.78H10.3C10.73 6.78 11.07 6.89 11.3 7.12C11.54 7.34 11.65 7.64 11.65 8.01C11.65 8.38 11.54 8.68 11.32 8.91C11.1 9.14 10.8 9.26 10.41 9.26H10.24C9.83995 9.26 9.54995 9.34 9.37995 9.5C9.20995 9.66 9.11995 9.94 9.11995 10.34V11.78H10.14V10.62C10.14 10.47 10.17 10.36 10.23 10.3C10.29 10.24 10.4 10.2 10.55 10.2C10.93 10.2 11.29 10.11 11.62 9.93C11.95 9.75 12.21 9.49 12.41 9.16C12.61 8.83 12.7 8.45 12.7 8.01C12.7 7.57 12.61 7.18 12.42 6.85C12.23 6.52 11.97 6.26 11.63 6.08H11.64Z" />
    </Icon>
  );
};
