import { Icon, SvgProps } from '../common/Icon';

export const Dash = (props: SvgProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path d="M3.02002 8.27995V9.24995L4.47002 7.83995V13H5.25002V6.69995H4.65002L3.02002 8.27995Z" />
      <path d="M10.05 6.97999C9.69003 6.72999 9.25003 6.60999 8.73003 6.60999C8.21003 6.60999 7.77003 6.72999 7.41003 6.97999C7.04003 7.22999 6.77003 7.58999 6.57003 8.07999C6.38003 8.56999 6.28003 9.15999 6.28003 9.84999C6.28003 10.54 6.38003 11.14 6.57003 11.62C6.76003 12.11 7.04003 12.47 7.41003 12.72C7.78003 12.97 8.22003 13.09 8.73003 13.09C9.24003 13.09 9.69003 12.97 10.05 12.72C10.41 12.47 10.69 12.11 10.88 11.62C11.07 11.13 11.17 10.54 11.17 9.84999C11.17 9.15999 11.07 8.55999 10.88 8.07999C10.69 7.58999 10.41 7.22999 10.05 6.97999ZM10.14 11.35C9.98003 11.73 9.78003 12 9.54003 12.16C9.30003 12.32 9.05003 12.4 8.78003 12.4H8.69003C8.43003 12.4 8.17003 12.32 7.93003 12.16C7.69003 12 7.48003 11.73 7.32003 11.35C7.16003 10.97 7.08003 10.47 7.08003 9.85998C7.08003 9.24998 7.16003 8.73998 7.32003 8.36999C7.48003 7.98999 7.68003 7.71999 7.93003 7.55999C8.18003 7.39999 8.43003 7.31999 8.69003 7.31999H8.78003C9.04003 7.31999 9.30003 7.39999 9.54003 7.55999C9.78003 7.71999 9.98003 7.98999 10.14 8.36999C10.3 8.74999 10.38 9.24998 10.38 9.85998C10.38 10.47 10.3 10.98 10.14 11.35Z" />
      <path d="M16.47 8.07999C16.28 7.58999 16 7.22999 15.64 6.97999C15.28 6.72999 14.84 6.60999 14.32 6.60999C13.8 6.60999 13.36 6.72999 13 6.97999C12.63 7.22999 12.36 7.58999 12.16 8.07999C11.97 8.56999 11.87 9.15999 11.87 9.84999C11.87 10.54 11.97 11.14 12.16 11.62C12.35 12.11 12.63 12.47 13 12.72C13.37 12.97 13.81 13.09 14.32 13.09C14.83 13.09 15.28 12.97 15.64 12.72C16 12.47 16.28 12.11 16.47 11.62C16.66 11.13 16.76 10.54 16.76 9.84999C16.76 9.15999 16.66 8.55999 16.47 8.07999ZM15.72 11.35C15.56 11.73 15.36 12 15.12 12.16C14.88 12.32 14.63 12.4 14.36 12.4H14.27C14.01 12.4 13.75 12.32 13.51 12.16C13.27 12 13.06 11.73 12.9 11.35C12.74 10.97 12.66 10.47 12.66 9.85998C12.66 9.24998 12.74 8.73998 12.9 8.36999C13.06 7.98999 13.26 7.71999 13.51 7.55999C13.76 7.39999 14.01 7.31999 14.27 7.31999H14.36C14.62 7.31999 14.88 7.39999 15.12 7.55999C15.36 7.71999 15.56 7.98999 15.72 8.36999C15.88 8.74999 15.96 9.24998 15.96 9.85998C15.96 10.47 15.88 10.98 15.72 11.35Z" />
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 19C5.04 19 1 14.96 1 10C1 5.04 5.04 1 10 1C14.96 1 19 5.04 19 10C19 14.96 14.96 19 10 19Z" />
    </Icon>
  );
};
