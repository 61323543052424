import {useAuth0} from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import * as React from 'react'
import {useLocation} from 'react-router-dom'

import {Account} from '@ansarada/glyphs/shared/actions/Account'
import {SecurityVariant} from '@ansarada/glyphs/shared/actions/SecurityVariant'
import {Dash} from '@ansarada/glyphs/shared/navigation/Dash'
import {Help} from '@ansarada/glyphs/shared/status/Help'
import {PlatformHeader} from '@ansarada/ui/lib/platform-header/platform-header'

import appConfig from '~/config'
import type {ProductName} from '~/types'

import {withTheme} from './hoc/withTheme'

const Header = withTheme(
  'ACELight',
  ({userName, productName}: {userName: string; productName: ProductName}) => {
    const {logout} = useAuth0()
    const location = useLocation()

    const productMenu = React.useMemo(
      () => [
        [
          {
            id: 'deals',
            icon: <Dash />,
            label: 'Dash',
            onClick: () =>
              window.open(`${process.env.DEALS_URL ?? ''}`, '_blank')?.focus(),
          },
        ],
      ],
      [],
    )

    const userMenu = React.useMemo(
      () => [
        [
          {
            id: 'manage-heading',
            isHeading: true,
            label: 'MANAGE',
          },
          {
            id: 'edit-profile',
            icon: <Account />,
            label: 'Profile',
            onClick: () =>
              window
                .open(`${appConfig.identityUrl ?? ''}/profile`, '_blank')
                ?.focus(),
          },
        ],
        [
          {
            id: 'help',
            label: 'Help & support',
            icon: <Help />,
            onClick: () =>
              window.open('https://help.ansarada.com', '_blank')?.focus(),
          },
          {
            id: 'logout',
            icon: <SecurityVariant />,
            label: 'Log out',
            onClick: () => {
              const redirectAfterLogout = encodeURIComponent(location.pathname)
              logout({
                returnTo: `${appConfig.auth.logoutRedirectUri}?redirectAfterLogout=${redirectAfterLogout}`,
              })
            },
          },
        ],
      ],
      [logout, location.pathname],
    )

    return (
      <Box data-test-id="header">
        <PlatformHeader
          userName={userName}
          productMenu={productMenu}
          productName={productName}
          userMenu={userMenu}
          sx={theme => ({
            position: 'static',
            backgroundColor: theme.palette.common.black,
          })}
        />
      </Box>
    )
  },
)

export {Header}
