import { SvgIcon, SvgIconProps } from '@mui/material';

type Size = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

function getSize(size: Size) {
  switch (size) {
    case 'small':
      return 13;
    case 'medium':
      return 20;
    case 'large':
      return 24;
    case 'xlarge':
      return 32;
    case 'xxlarge':
      return 60;
    default:
      return 20;
  }
}

type SvgProps = SvgIconProps & { size?: Size };

// Try all 5 sizes, color, other props
const Icon = (props: SvgProps) => {
  const { size = 'medium', color, sx, ...rest } = props;
  return (
    <SvgIcon
      {...rest}
      viewBox={props.viewBox || `0 0 ${getSize(size)} ${getSize(size)}`}
      sx={{ fontSize: `${getSize(size)}px`, color: color || '#07070C', ...sx }}
    />
  );
};

export { Icon };
export type { SvgProps };
