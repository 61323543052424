import { Icon, SvgProps } from '../common/Icon';

export const SecurityVariant = (props: SvgProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path d="M16 10H5V6C5 3.24 7.24 1 10 1C12.76 1 15 3.24 15 6V7H16V6C16 2.69 13.31 0 10 0C6.69 0 4 2.69 4 6V10C2.9 10 2 10.9 2 12V18C2 19.1 2.9 20 4 20H16C17.1 20 18 19.1 18 18V12C18 10.9 17.1 10 16 10ZM17 18C17 18.55 16.55 19 16 19H4C3.45 19 3 18.55 3 18V12C3 11.45 3.45 11 4 11H16C16.55 11 17 11.45 17 12V18Z" />
      <path d="M14 13H6V14H14V13Z" />
      <path d="M14 16H6V17H14V16Z" />
    </Icon>
  );
};
