import { Icon, SvgProps } from '../common/Icon';

export const Account = (props: SvgProps) => {
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <path d="M16.78 10.34C16.91 9.75 17 9.13 17 8.5C17 3.81 13.19 0 8.5 0C3.81 0 0 3.81 0 8.5C0 13.19 3.81 17 8.5 17C9.13 17 9.75 16.91 10.34 16.78C11.06 18.66 12.87 20 15 20C17.76 20 20 17.76 20 15C20 12.87 18.66 11.06 16.78 10.34ZM1 8.5C1 4.36 4.36 1 8.5 1C12.64 1 16 4.36 16 8.5C16 9.05 15.92 9.57 15.81 10.08C15.54 10.04 15.28 10 15 10C14.01 10 13.1 10.3 12.33 10.79C11.85 10.43 11.29 10.17 10.68 10.07C11.45 9.34 11.95 8.24 11.95 7.01C11.95 4.8 10.38 3.01 8.45 3.01C6.52 3.01 4.95 4.8 4.95 7.01C4.95 8.26 5.46 9.35 6.24 10.09C4.52 10.43 3.21 11.87 3.04 13.64C1.78 12.3 1 10.5 1 8.52V8.5ZM5.95 7C5.95 5.35 7.07 4 8.45 4C9.83 4 10.95 5.35 10.95 7C10.95 8.65 9.83 10 8.45 10C7.07 10 5.95 8.65 5.95 7ZM8.5 16C6.81 16 5.26 15.43 4 14.49V14C4 12.35 5.35 11 7 11H10C10.55 11 11.07 11.16 11.51 11.43C10.58 12.34 10 13.6 10 15C10 15.28 10.04 15.55 10.08 15.81C9.57 15.92 9.04 16 8.5 16ZM15 19C12.79 19 11 17.21 11 15C11 12.79 12.79 11 15 11C17.21 11 19 12.79 19 15C19 17.21 17.21 19 15 19Z" />
      <path d="M16.93 14.5C16.88 14.32 16.81 14.15 16.72 13.99L17.48 13.23L16.77 12.52L16.01 13.28C15.85 13.19 15.68 13.11 15.5 13.07V12H14.5V13.07C14.32 13.12 14.15 13.19 13.99 13.28L13.23 12.52L12.52 13.23L13.28 13.99C13.19 14.15 13.11 14.32 13.07 14.5H12V15.5H13.07C13.12 15.68 13.19 15.85 13.28 16.01L12.52 16.77L13.23 17.48L13.99 16.72C14.15 16.81 14.32 16.89 14.5 16.93V18H15.5V16.93C15.68 16.88 15.85 16.81 16.01 16.72L16.77 17.48L17.48 16.77L16.72 16.01C16.81 15.85 16.89 15.68 16.93 15.5H18V14.5H16.93ZM15 16C14.45 16 14 15.55 14 15C14 14.45 14.45 14 15 14C15.55 14 16 14.45 16 15C16 15.55 15.55 16 15 16Z" />
    </Icon>
  );
};
