import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  SxProps,
  buttonClasses,
  circularProgressClasses,
  styled,
} from '@mui/material';
import cls from 'classnames';
import { forwardRef } from 'react';

import { UtilityProps } from '../../utils/prop';
import { Spinner } from '../Spinner';

export enum ButtonVariant {
  CAUTION = 'caution',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  GHOST = 'ghost',
  CHAOS = 'chaos',
}

export enum ButtonSize {
  XLARGE = 'xlarge',
  LARGE = 'large',
  REGULAR = 'regular',
}

type BaseProps = {
  /** The variant of Button */
  variant?: ButtonVariant | `${ButtonVariant}`;

  /** The size of Button */
  size?: ButtonSize | `${ButtonSize}`;

  /** If `true`, Button will enter loading state */
  loading?: boolean;
} & UtilityProps;

type Props = BaseProps & Omit<MuiButtonProps, keyof BaseProps | 'color' | 'endIcon'>;

const classes = {
  primary: 'Button--primary',
  secondary: 'Button--secondary',
  caution: 'Button--caution',
  ghost: 'Button--ghost',
  chaos: 'Button--chaos',

  xlarge: 'Button--xlarge',
  large: 'Button--large',
  regular: 'Button--regular',

  loading: 'Button--loading',
};

/** This component inherits [MUI Button's API](https://mui.com/material-ui/api/button/)\
 * See the [API documented on Storybook](https://ansarada-design-system.vercel.app/?path=/docs/elements-button-props--documentation)
 */
const Button = styled(
  forwardRef<HTMLButtonElement, Props>(
    (
      {
        children,
        loading = false,
        startIcon: startIconProp,
        size = ButtonSize.REGULAR,
        variant = ButtonVariant.PRIMARY,
        ...props
      }: Props,
      ref,
    ) => {
      const startIcon = loading ? undefined : startIconProp;

      return (
        <MuiButton
          {...props}
          variant={variant}
          startIcon={startIcon}
          ref={ref}
          className={cls([
            props.className,
            classes[variant],
            classes[size],
            loading && classes.loading,
          ])}
        >
          {loading && <Spinner thickness={2} size={20} />}
          {!loading && children}
        </MuiButton>
      );
    },
  ),
)(({ theme: { palette, spacing } }) => {
  return {
    '&': {
      height: '36px',

      [`&.${classes.loading}`]: {
        padding: `${spacing(2, 4)} !important`,

        [`.${circularProgressClasses.root}`]: {
          color: palette._grey._600,
        },

        [`&.${buttonClasses.disabled}`]: {
          [`.${circularProgressClasses.root}`]: {
            color: palette._grey._600,
          },
        },

        [`&.${classes.caution}, &.${classes.primary}`]: {
          [`.${circularProgressClasses.root}`]: {
            color: palette.order,
          },
        },
      },

      [`&.${classes.xlarge}`]: {
        minWidth: '220px',
      },

      [`&.${classes.large}`]: {
        minWidth: '160px',
      },

      [`&.${classes.regular}`]: {
        minWidth: '100px',
      },

      [`&.${classes.primary}`]: {
        [`&.${classes.loading}`]: {
          backgroundColor: palette._grey._700,
        },
      },

      [`&.${classes.caution}`]: {
        [`&.${classes.loading}`]: {
          backgroundColor: palette.neon._500,
          boxShadow: `inset 0 0 0 0px ${palette._grey._700}`,
        },
      },

      [`&.${classes.ghost}`]: {
        [`&.${classes.loading}`]: {
          backgroundColor: palette.order,
          boxShadow: `inset 0 0 0 0px ${palette._grey._600}`,
        },
      },

      [`&.${classes.secondary}`]: {
        [`&.${classes.loading}`]: {
          boxShadow: `inset 0 0 0 1px ${palette._grey._600}`,
        },
      },
    },
  } satisfies SxProps;
});

Button.displayName = 'Button';

export { Button, classes as buttonClasses };
export type ButtonProps = Props;
